/// Border style for the accordion
$accordion-border: 2px solid $color--neutral;

/// Hover color for the accordion
$accordion-inactive-color: $color--neutral;

/// Hover color for the accordion
$accordion-active-color: $color--primary-dark;


// =============================================================================
// Wrapper
// -----------------------------------------------------------------------------

.accordion {
  border-bottom: $accordion-border;
}


// =============================================================================
// Titles
// -----------------------------------------------------------------------------

.accordion__title {
  align-items: center;
  border-top: $accordion-border;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  .accordion__title-text {
    @include margin(y, 25px);
    color: $accordion-inactive-color;
    transition: color get-timing(normal);
  }

  &::after {
    @include icon-font;
    content: '\2304'; // Down chevron
    transition:
      color get-timing(normal),
      transform get-timing(normal);
  }

  &:hover,
  &.expanded {
    .accordion__title-text {
      color: $accordion-active-color;
    }

    &::after {
      color: $accordion-active-color;
    }
  }

  &.expanded {
    &::after {
      transform: rotate(180deg);
    }
  }
}


// =============================================================================
// Content
// -----------------------------------------------------------------------------

.accordion__content {
  @include responsive-padding(x);
  @include padding(bottom, 25px);
}
