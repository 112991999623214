.title-bar {
  @include title-bar;
}

.title-bar--with-nav {
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}


// =============================================================================
// Title Text
// -----------------------------------------------------------------------------

.title-bar__title {
  @include responsive-margin(right);
  @include margin(y, 0);

  @include breakpoint(above, medium) {
    white-space: nowrap;
  }
}

.title-bar__subtitle {
  margin: 0;
  width: 100%;
}


// =============================================================================
// Basic Nav Link
// -----------------------------------------------------------------------------

.title-bar__nav {
  @include font-sizing(B);
  font-family: $font-family--display;
  font-weight: normal;

  a {
    align-items: center;
    border: 0;
    color: $color--black;
    display: flex;
  }

  .icon {
    margin-right: 10px;
  }
}


// =============================================================================
// Pager
// -----------------------------------------------------------------------------

.title-bar__pager {
  .pager {
    @include margin(y, 0);
    @include no-bullets;
    align-items: center;
    display: flex;
  }

  li {
    display: inline-block;

    a {
      border: 0;
      color: $color--neutral-light;
      display: block;
      font-size: 21px;

      &:hover {
        color: $color--black;
      }
    }
  }

  .icon {
    display: block;
  }

  .pager-all {
    margin-right: 2em;

    a {
      color: $color--black;

      &:hover {
        color: $color--primary-dark;
      }
    }
  }

  .pager-current {
    color: $color--neutral;
    font-weight: normal;
    white-space: nowrap;
  }

  // 1:  The drupal mini-pager doesn't remove the <li> elements for previous
  //     when on the first page or for 'next' when on the last page. Instead, it
  //     keeps the <li> but replaces the link itself with a non-breaking space.
  //
  //     To hide these links, we have to set the font-size to 0, then reset it
  //     on the links themselves.
  .pager-previous,
  .pager-next {
    @include icon-font;
    font-size: 0; // 1
  }

  .pager-previous a {
    margin-right: 1em;
  }

  .pager-next a {
    margin-left: 1em;
  }

  .pager-previous + .pager-next a {
    margin-left: 0;
  }
}
