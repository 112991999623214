.hamburger {
  @include margin(left, xx-small);
  display: block;

  @include breakpoint(above, large) {
    display: none;
  }
}

.hamburger__button {
  align-items: center;
  color: $color--black;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: bold;
  justify-content: space-between;
}

.hamburger__text {
  display: none;
  transition: color get-timing(normal) ease-in;

  @include breakpoint(above, medium) {
    display: block;
    text-transform: uppercase;
  }
}

.hamburger__icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 19px;
  margin-right: 10px;
  position: relative;
  width: 25px;
}

[class^='hamburger__icon-bar'] {
  background-color: $color--black;
  display: block;
  height: 3px;
  transition:
    background-color get-timing(normal) ease-in,
    height get-timing(normal) ease-in,
    transform get-timing(normal) ease-out !important;
  width: 100%;

  .hamburger__button:hover &,
  .hamburger__button:focus & {
    background-color: $color--primary;
  }

  .js-show-menu & {
  }
}


.js-show-menu {
  .hamburger__text {
    color: $color--primary;

    &:hover,
    &:focus {
      color: $color--primary-dark;
    }
  }

  [class^='hamburger__icon-bar']  {
    background-color: $color--primary;
  }

  .hamburger__icon-bar--top {
    transform: translateY(300%) rotate(-135deg);
  }

  .hamburger__icon-bar--mid {
    background-color: transparentize($color--black, 1);
    height: 0;
    transform: scale(0);
  }

  .hamburger__icon-bar--bottom {
    transform: translateY(-250%) rotate(135deg);
  }

  .hamburger__button:hover,
  .hamburger__button:focus {
    [class^='hamburger__icon-bar']  {
      background-color: $color--primary-dark;
    }
  }
}
