.section--what-is-uwall {
  @include responsive-padding(y);
  background-image: url('../images/background-grid.png');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;

  @include breakpoint(above, medium) {
    background-position: center;
    background-size: auto;

    .column--middle {
      order: 1;
    }

    .column--left {
      order: 2;
    }

    .column--right {
      order: 3;
    }
  }
}
// =============================================================================
// Advantages Slider
// -----------------------------------------------------------------------------

.section-features-benefits .slider--with-overlay {
  .overlay {
    @include responsive-margin(y);
    @include responsive-padding(all);

    @include breakpoint(below, medium) {
      @include margin(y, 0);
      background: $color--black;
      position: static;
    }

    @include breakpoint(only, medium) {
      @include margin(y, 0);
      top: 50%;
      transform: translateY(-50%);
      height: 85%;
    }

    @include breakpoint(above, medium) {
      left: 40%;
    }

    @include breakpoint(above, x-large) {
      height: auto;
      top: 0;
      left: 50%;
      transform: none;
    }

    @include breakpoint(above, xxx-large) {
      left: percentage(2 / 3);
    }
  }

  .list--with-icons {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;

    @include breakpoint(above, x-large) {
      justify-content: space-between;
    }

    li {
      font-weight: normal;
    }
  }

  .slide-caption {
    @include font-sizing(D);
    color: $color--neutral-light;
    font-weight: normal;

    @include breakpoint(above, large) {
      @include font-sizing(E);
    }

    @include breakpoint(above, xxx-large) {
      @include font-sizing(F);
    }
  }

  .overlay__desc {
    @include breakpoint(only, medium) {
      @include font-sizing(D);
    }

    @include breakpoint(above, large) {
      @include font-sizing(E);
    }

    @include breakpoint(above, xx-large) {
      @include font-sizing(F);
    }

    @include breakpoint(above, xxx-large) {
      @include font-sizing(G);
    }
  }
}


.view-display-id-illustrations {
  background-image: url('../images/background-grid.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @include breakpoint(above, small) {
    background-size: auto;
  }

  @include breakpoint(above, xxx-large) {
    background-image:
      url('../images/background-grid.png'),
      url('../images/background-grid.png'),
      url('../images/background-grid.png');

    background-position:
      50% 75%,
      -25% 175%,
      125% -25%;
  }
}
