.node-article {
  @include responsive-padding(x);
  @include responsive-margin(y);
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.news-article__byline {
  @include news-byline;
}

.news-article__category {
  @include news-category;
}
