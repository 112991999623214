.slider {
  position: relative;
  z-index: get-z-index(slider);
}

.flexslider {
  background-color: transparent;
  border: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

.slides,
.flex-active-slide,
.js-responsive-background {
  background-size: cover;
  height: 100%;
  width: 100%;
}


// =============================================================================
// Nav Arrows
// -----------------------------------------------------------------------------

.flex-direction-nav {
  .flex-nav-prev .flex-prev,
  .flex-nav-next .flex-next {
    @include square(40px, transparentize($color--black, .6));
    align-items: center;
    border: 0;
    display: flex;
    font-size: 0;
    justify-content: center;
    position: absolute;
    transition: background-color get-timing(normal);

    &:hover {
      background: $color--black;
    }

    &::before {
      @include icon-font;
      color: $color--white;
      font-size: 15px;
      text-shadow: none;
    }

    @include breakpoint(above, x-small) {
      &::before {
        font-size: 17px;
      }
    }

    @include breakpoint(above, small) {
      @include square(50px);

      &::before {
        font-size: 19px;
      }
    }

    @include breakpoint(above, medium) {
      @include square(60px);

      &::before {
        font-size: 21px;
      }
    }

    .flexslider:hover & {
      display: flex;
    }
  }

  .flex-nav-prev .flex-prev {
    &,
    .flexslider:hover & {
      @include responsive-position(left);
      left: 0;
      opacity: 1;
    }

    &::before {
      content: '\25c0'; // Left Chevron
    }
  }

  .flex-nav-next .flex-next {
    &,
    .flexslider:hover & {
      @include responsive-position(right);
      right: 0;
      opacity: 1;
    }

    &::before {
      content: '\25b6'; // Right Chevron
    }
  }
}


// =============================================================================
// Default Sliders
// -----------------------------------------------------------------------------

.flex-control-nav {
  @include responsive-position(bottom);
  @include responsive-position(left);
  display: none;
  flex-direction: column;
  position: absolute;
  width: auto;
  z-index: get-z-index(slider, +1);

  @include breakpoint(above, medium) {
    display: flex;
  }

  li {
    margin-left: 0;
    margin-right: 0;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  li a {
    @include rectangle(60px, 10px, transparentize($color--black, .4));
    border: 0;
    border-radius: 0;
    box-shadow: none;
    transform-origin: left;
    transition:
      background-color get-timing(fast),
      transform get-timing(fast);

    &:hover {
      background-color: $color--black;
    }

    &.flex-active {
      background-color: $color--black;
      transform: scaleX(2);
    }
  }
}


// =============================================================================
// Sliders with HTML Content
// -----------------------------------------------------------------------------

.slider--with-content {
  .slide__inner {
    @include responsive-padding(all);
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .slide__image-wrapper {
    width: 100%;

    img {
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      width: auto;
    }
  }
}


// =============================================================================
// Overlays
// -----------------------------------------------------------------------------

.overlay {
  @include position(absolute, null 0 0);
  @include responsive-padding(all);
  align-items: center;
  background: transparentize($color--black, .3);
  display: flex;
  min-height: 200px;
  text-align: center;
  z-index: get-z-index(masthead, -1);

  @include breakpoint(above, x-small) {
    @include position(absolute, null 0 0);
    min-height: 50%;
  }

  @include breakpoint(only, medium) {
    text-align: left;
  }

  @include breakpoint(above, large) {
    @include position(absolute, 0 0 0 50%);
    align-items: flex-start;
  }

  &.fade-up {
    animation-duration: get-timing(slow);
    animation-fill-mode: both;
    animation-name: fade-up;
    animation-timing-function: ease-in-out;
    animation-play-state: paused;
  }

  &.js-animated {
    animation-play-state: running;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.overlay__desc {
  @include font-sizing(base);
  color: $color--white;
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  @include breakpoint(above, x-large) {
    @include font-sizing(B);
  }

  @include breakpoint(above, xxx-large) {
    @include font-sizing(D);
  }
}

.overlay__inner {
  height: 100%;
}
