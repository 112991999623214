.section-about {
  .text-reveals {
    @include breakpoint(below, x-large) {
      @include padding(x, 0);
    }
  }

  .text-reveal {
    min-height: 0;
    height: 440px;

    @include breakpoint(above, small) {
      width: 50%;
      margin-bottom: 1px;

      &:nth-last-child(-n+2) {
        margin-bottom: 0;
      }
    }

    @include breakpoint(above, x-large) {
      width: percentage(1 / 3);

      &:nth-last-child(-n+3) {
        margin-bottom: 0;
      }
    }
  }

  .text-reveal::before,
  .text-reveal__image {
    left: 0;

    @include breakpoint(above, small) {
      left: 1px;
    }
  }
}
