@mixin aspect-ratio($width, $height, $selector: '> .content') {
  @include clearfix;
  position: relative;

  &::before {
    content: '';
    display: block;
    float: left;
    padding-top: percentage($height / $width);
    width: 100%;
  }

  #{$selector} {
    @include position(absolute, 0);
  }
}

.hero {
  @include aspect-ratio(16, 9);

  @include breakpoint(below, x-large) {
    &.js-fullscreen {
      height: auto !important;
    }
  }

  @include breakpoint(above, x-large) {
    &.js-fullscreen::before {
      display: none;
    }
  }
}
