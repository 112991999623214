@mixin news-byline {
  @include font-sizing(base);
  @include padding(bottom, 20px);
  @include margin(bottom, 20px);
  border-bottom: 2px solid $color--neutral;
  color: $color--neutral;
  display: inline-block;
  font-weight: bold;

  @include breakpoint(above, large) {
    @include font-sizing(A);
  }

  // Add a separating slash between all immediate children
  > :not(:first-child)::before {
    content: ' / ';
  }
}

@mixin news-category {
  text-transform: uppercase;
}
