// =============================================================================
// Scroll Down Indicator
// -----------------------------------------------------------------------------

.region-above-content {
  position: relative;
}

.scroll-down {
  $color--scroll-down: $color--white;
  $color--shadow: transparentize($color--black, .5);

  $inner-width: 16px;
  $outer-width: 40px;
  $border-width: 3px;
  $gap-width: 4px + ((($outer-width - $border-width) - $inner-width) / 2);

  @include circle($outer-width);
  @include responsive-position(right);
  @include responsive-position(bottom);

  background: $color--shadow;
  border: 3px solid $color--scroll-down;
  box-shadow: 0 5px 15px $color--shadow;
  display: none;
  position: absolute;
  animation: 1000ms ease-in-out scroll-down-pulse infinite;
  text-align: center;
  transition: opacity get-timing(normal) ease-in;
  z-index: 999;

  @include breakpoint(above, large) {
    @include responsive-position(left);
    display: block;
    right: auto;
  }

  &::before {
    // @include circle($inner-width - 4px, $color--scroll-down);
    // box-shadow: 0 0 0 $color--scroll-down;
    // transition: box-shadow get-timing(normal) ease-in;
    @include position(absolute, 55% null null 50%);
    @include icon-font;
    content: '\2304';
    color: $color--white;
    transform: translate(-50%, -50%);
  }

  &:hover,
  &:focus,
  &:active {
    animation: none;
    border-bottom-color: $color--scroll-down !important;
    opacity: 1 !important;
  }
}

.scroll-down__text {
  color: $color--white;
  font-size: get-font-size(a);
  left: 50%;
  position: absolute;
  text-transform: uppercase;
  top: 100%;
  transform: translate(-50%, 5px);
}

.with-scroll-down {
  position: relative;

  &::after {
    display: none;

    @include breakpoint(above, large) {
      @include position(absolute, 75% 0 0);
      background: linear-gradient(
        to top,
        $color--black 0%,
        transparentize($color--black, 1) 100%
      );
      content: '';
      display: block;
      z-index: get-z-index(slider, +1);
    }
  }
}
