.engineering-document {
  align-items: center;
  border-bottom: 0;
  display: flex;
  margin-bottom: 25px;

  &:hover {
    border-bottom-color: transparent;
  }

  .views-row-last & {
    margin-bottom: 0;
  }
}

.engineering-document__icon {
  border-bottom: 0;
  margin-right: 30px;
  opacity: .5;
  text-decoration: none;
  transition: opacity get-timing(normal);

  &:hover,
  &:focus {
    opacity: 1;
  }
}
