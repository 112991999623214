/// Spacing to use between columns
/// @type Map
$spacing-columns: $spacing-default;

/// Creates columns
@mixin columns($num-columns, $column-selector: '.column') {
  @include responsive-margin(left, -1, $scale: $spacing-columns);
  display: flex;
  flex-flow: row wrap;

  #{$column-selector} {
    @include responsive-padding(left, $scale: $spacing-columns);
    width: 100% / $num-columns;

    &:nth-child(n+#{$num-columns + 1}):nth-last-child(-n+#{$num-columns}) {
      margin-bottom: 0;
    }
  }
}


.column {
  @include responsive-margin(bottom);
  z-index: get-z-index(masthead, -5);

  .into-view & {
    $timing: get-timing(slow);
    $timing-overlap-ratio: .3;

    animation-duration: $timing;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
    animation-play-state: paused;

    &:nth-child(2) {
      animation-delay: $timing * $timing-overlap-ratio;
    }

    &:nth-child(3) {
      animation-delay: $timing * ($timing-overlap-ratio * 2);
    }

    &:nth-child(4) {
      animation-delay: $timing * ($timing-overlap-ratio * 3);
    }
  }

  .js-animated & {
    animation-play-state: running;
  }

  .fade-up & {
    animation-name: fade-up;
  }

}


// =============================================================================
// Three Columns
// -----------------------------------------------------------------------------

.two-columns--medium-up {
  @include responsive-padding(x);

  @include breakpoint(above, medium)  {
    @include columns(2);
  }
}

.two-columns--medium-up-no-gutter {
  @include responsive-padding(x);

  @include breakpoint(above, medium)  {
    @include columns(2);
  }
}


// =============================================================================
// Three Columns
// -----------------------------------------------------------------------------

.three-columns--medium-up {
  @include responsive-padding(x);

  @include breakpoint(above, medium)  {
    @include columns(3);
  }
}

.three-columns--medium-up-no-gutter {
  @include responsive-padding(x);

  @include breakpoint(above, medium)  {
    @include columns(3);
  }
}
