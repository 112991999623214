////
/// Styles for the Contact block in the header
/// @group Masthead
////

.masthead__contact {
  @include margin(y, 0);
  @include margin(x, xx-small, -.5);
  @include no-bullets;
  align-items: center;
  display: flex;
  justify-content: flex-end;

  @include breakpoint(above, large) {
    @include margin(x, x-small, -.5);
    @include margin(bottom, 15px);
  }
}

.masthead__contact-item {
  @include padding(x, xx-small, .5);
  font-weight: bold;

  @include breakpoint(above, large) {
    @include padding(x, x-small, .5);
  }
}

.masthead__contact-link {
  @include font-sizing(a);
  color: $color--black;

  .icon {
    display: block;
    font-size: 20px;
  }

  &:hover {
    color: $color--primary;
  }
}
