////
/// Style overrides for the home page
////

$intro-base-animation-delay: get-timing(slow) * .75;
$intro-animation-duration: get-timing(slow) * 1.5;
$intro-animation-delay: $intro-animation-duration / 4;

.front .main-content {
  h1 {
    @include font-sizing(I, 1);
    color: $color--primary;
    margin-bottom: 20px;

    @include breakpoint(above, small) {
      @include font-sizing(J, 1);
    }

    @include breakpoint(above, large) {
      @include font-sizing(L);
    }

    @include breakpoint(above, xx-large) {
      @include font-sizing(M);
    }
  }

  h2 {
    @include responsive-padding(x);
    @include font-sizing(G);
    color: $color--primary;
    margin-bottom: 30px;

    @include breakpoint(above, x-small) {
      @include font-sizing(H);
      margin-bottom: 40px;
    }

    @include breakpoint(above, large) {
      @include font-sizing(I);
      margin-bottom: 50px;
    }

    @include breakpoint(above, xx-large) {
      @include font-sizing(K);
      margin-bottom: 70px;
    }
  }

  h3 {
    @include font-sizing(D);
    color: $color--black;

    @include breakpoint(above, x-small) {
      @include font-sizing(E);
    }

    @include breakpoint(above, large) {
      @include font-sizing(F);
    }

    @include breakpoint(above, xx-large) {
      @include font-sizing(G);
    }
  }
}


// =============================================================================
// Slider
// -----------------------------------------------------------------------------

.front #slider--with-captions {
  .js-fullscreen {
    @include breakpoint(above, large) {
      min-height: 700px;
    }
  }

}

.front .slider--with-overlay {
  .overlay__desc {
    @include breakpoint(only, medium) {
      width: 50%;
    }
  }
}


// =============================================================================
// Homepage Intro
// -----------------------------------------------------------------------------

.block--intro {
  background-color: $color--white;
  background-image: url('/sites/all/themes/uwall/images/grunge.jpg');
  background-position: center 20%;
  background-repeat: no-repeat;
  position: relative;

  @include breakpoint(above, medium) {
    background-position: right center;

    .block__title {
      transform: translateY(500%);
    }
  }
}

.intro__inner {
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  @include breakpoint(above, medium) {
    flex-direction: row;
    justify-content: space-between;
  }
}


// =============================================================================
// Copy
// -----------------------------------------------------------------------------

.intro__copy {
  text-align: center;

  @include breakpoint(above, medium) {
    text-align: left;
  }
}

.intro__list {
  @include responsive-margin(y)
}

.intro__lines {
  @include position(absolute, null -30% null null);
  @include responsive-position(top);
  animation-delay: $intro-base-animation-delay + ($intro-animation-duration * .75);
  animation-duration: $intro-animation-duration * .75;
  animation-fill-mode: both;
  animation-name: rotate-x;
  animation-timing-function: ease-in;
  animation-play-state: paused;
  display: none;

  @include breakpoint(above, xx-large) {
    display: block;
    right: 50%;
  }
}

.intro__list li,
.intro__button {
  animation-duration: $intro-animation-duration;
  animation-fill-mode: both;
  animation-name: fade-up;
  animation-play-state: paused;
  animation-timing-function: ease-in-out;
}

.intro__list li {
  @for $i from 1 through 5 {
    &:nth-child(#{$i}) {
      $delay: ($intro-animation-delay * ($i + 2)) - ($intro-animation-duration / 2);
      animation-delay: $delay;
    }
  }

  @include breakpoint(above, medium) {
    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        $delay: $intro-animation-delay * ($i + 2);
        animation-delay: ($intro-base-animation-delay / 2) + $delay;
      }
    }
  }
}

.intro__button {
  animation-delay: $intro-base-animation-delay + ($intro-animation-delay * 8);
  margin-top: 2em;
}


// =============================================================================
// Block Illustrations
// -----------------------------------------------------------------------------

.intro__blocks {
  @include responsive-margin(top);
  text-align: center;

  @include breakpoint(above, medium) {
    margin-top: 0 !important;
  }
}

.intro__block {
  $section-padding--medium: 60px;
  $section-padding--large: 70px;
  $section-padding--x-large: 90px;
  width: 100%;

  @include breakpoint(above, medium) {
    @include position(absolute, null null null 80%);
  }

  @include breakpoint(above, large) {
    left: 75%;
  }


  // Top Block
  // ---------------------------------------------------------------------------
  &:first-child {
    @include responsive-position(top);
    z-index: 500;

    @include breakpoint(above, medium) {
      transform:
        translate(-50%, -75%)
        translateY($section-padding--medium * -3);
    }

    @include breakpoint(above, large) {
      transform:
        translate(-50%, -175%)
        translateY($section-padding--large * -1);
    }

    @include breakpoint(above, x-large) {
      transform:
        translate(-50%, -140%)
        translateY($section-padding--x-large * -1);
    }
  }


  // Bottom Block
  // ---------------------------------------------------------------------------
  &:last-child {
    @include responsive-position(bottom, .5);
    margin-left: 50%;
    transform: translateX(-50%);

    @include breakpoint(above, medium) {
      margin-left: 0;
      opacity: 0;
      transform: translate(-50%, 25%);
    }
  }


  // Block images
  // ---------------------------------------------------------------------------
  .illustration {
    @include breakpoint(above, medium) {
      width: 180px;
    }

    @include breakpoint(above, large) {
      width: 250px;
    }

    @include breakpoint(above, x-large) {
      width: 300px;
    }

    @include breakpoint(above, xx-large) {
      max-width: 352px;
      width: auto;
    }
  }
}


// =============================================================================
// Animations
// -----------------------------------------------------------------------------

.js-animated {
  .intro__list li,
  .intro__button,
  .intro__lines {
    animation-play-state: running;
  }

  .intro__block {
    @include breakpoint(above, medium) {
      transition:
        opacity get-timing(intro-block) ease-in-out,
        transform get-timing(intro-block) ease-in-out;

      &:first-child {
        transform: translate(-50%, 50%);
      }

      &:last-child {
        opacity: 1;
        transform: translate(-50%, -55%);
      }
    }

    @include breakpoint(above, large) {
      &:first-child {
        transform: translate(-50%, 20%);
      }

      &:last-child {
        opacity: 1;
        transform: translate(-50%, -20%);
      }
    }

    @include breakpoint(above, x-large) {
      &:first-child {
        transform: translate(-50%, 0);
      }
    }

    @include breakpoint(above, xx-large) {
      &:last-child {
        transform: translate(-50%, -5%);
      }
    }
  }

  .block__title {
    margin-bottom: 0 !important;
    padding: 0 !important;

    @include breakpoint(above, medium) {
      transform: translateY(0);
      transition:
        transform ($intro-animation-duration) $intro-base-animation-delay get-easing(in-back);
    }
  }
}

