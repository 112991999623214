.paragraphs-item-half-page {
  .content {
    display: flex;
    flex-wrap: wrap;
  }

  [class*='field-column'] {
    @include responsive-padding(all);
    width: 100%;

    @include breakpoint(above, medium) {
      width: 50%;
    }
  }
}
