$button-height--small: 30px;
$button-height--medium: 40px;
$button-height--large: 50px;

.button,
button,
[type='button'],
[type='submit'],
%button {
  @include font-sizing(B);
  @include padding(x, x-small);
  background: $color--primary;
  border: 0;
  border-radius: 4px;
  color: $color--white;
  cursor: pointer;
  display: inline-block;
  font-family: $font-family--display;
  font-weight: normal;
  height: $button-height--medium;
  letter-spacing: .05em;
  line-height: $button-height--medium;
  text-transform: uppercase;
  transition: background get-timing('normal') ease-in;

  &:hover,
  &:focus,
  &:active {
    background: $color--primary-dark;
    color: $color--white;
  }

  &:active {
    position: relative;
    top: 1px;
  }

  @include breakpoint(above, xx-large) {
    height: $button-height--large;
    line-height: $button-height--large;
  }
}

.button--small {
  @extend %button;

  @include font-sizing(b);
  @include padding(x, xx-small);
  height: $button-height--small;
  line-height: $button-height--small;
}

.button-container,
%button-container {
  @include margin(top, 30px);
}
