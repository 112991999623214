@mixin with-background-image($image-url: null) {
  @if $image-url {
    background-image: url($image-url);
  }

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  p,
  label {
    color: $color--white;
  }

  .error {
    color: $color--primary;
  }
}
