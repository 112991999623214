////
/// Variables related to typography
/// @group Typography
////

/// Font family used for display text (e.g. titles, buttons)
/// @type string
$font-family--display: 'Bauhaus Demi', sans-serif;


/// Font family to use for normal text (e.g. body copy)
/// @type string
$font-family--normal: Helvetica, Arial, sans-serif;


/// Font family to use for icon fonts
$font-family--icon: icomoon;


/// Font sizes and line heights
///
/// @prop {map} step
/// @prop {number} step.font-size - The font size
/// @prop {number} step.line-height - The line height for the font size
$font-sizes: (
  // Smaller font sizes
  b: ( font-size: 14px, line-height: 23px ),
  a: ( font-size: 15px, line-height: 23px ),

  // The base font size
  base:  ( font-size: 16px, line-height: 23px ),

  // Bigger font sizes
  A: ( font-size: 17px, line-height: 24px ),
  B: ( font-size: 18px, line-height: 25px ),
  C: ( font-size: 19px, line-height: 26px ),
  D: ( font-size: 21px, line-height: 26px ),
  E: ( font-size: 23px, line-height: 28px ),
  F: ( font-size: 25px, line-height: 30px ),
  G: ( font-size: 27px, line-height: 32px ),
  H: ( font-size: 30px, line-height: 35px ),
  I: ( font-size: 35px, line-height: 40px ),
  J: ( font-size: 40px, line-height: 45px ),
  K: ( font-size: 45px, line-height: 50px ),
  L: ( font-size: 50px, line-height: 50px ),
  M: ( font-size: 60px, line-height: 60px ),
);


// =============================================================================
// Font Faces
// -----------------------------------------------------------------------------

@font-face {
  font-family: 'Bauhaus Demi';
  src:
  url('../fonts/bauhaus-demi/eecf586e-2db0-4062-a594-f868cc1b6361.eot?#iefix') format('eot'),
  url('../fonts/bauhaus-demi/46d6f23f-0b38-40f2-8ea1-5b08fc0c5815.woff2') format('woff2'),
  url('../fonts/bauhaus-demi/723fb32f-20c0-4370-b8e5-36c9195178e7.woff') format('woff'),
  url('../fonts/bauhaus-demi/035b0a07-933e-4328-baac-cba34f652484.ttf') format('truetype'),
  url('../fonts/bauhaus-demi/26b170b3-3731-4710-9d92-8fa70cc603b3.svg#26b170b3-3731-4710-9d92-8fa70cc603b3') format('svg');
}
