.text-reveal {
  border-bottom: 1px solid $color--off-white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 400px;
  overflow: hidden;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 1px;

    @include breakpoint(above, small) {
      margin-bottom: 0;
    }
  }

  &::before {
    @include position(absolute, 60% 0 0 1px);
    background: linear-gradient(
      to top,
      $color--black 0%,
      transparentize($color--black, 1) 100%
    );
    content: '';
    transition: top get-timing(normal);
    z-index: get-z-index('text-reveal-content', -1);
  }

  @include breakpoint(above, small) {
    margin-bottom: 0;
  }

  @include breakpoint(above, medium) {
    min-height: 460px;
  }

  @include breakpoint(above, x-large) {
    min-height: 550px;
  }

  @include breakpoint(above, xx-large) {
    min-height: 740px;
  }
}

.text-reveal__image {
  @include position(absolute, 0 0 0 1px);
  background-position: center;
  background-size: cover;
}

.text-reveal__inner {
  @include responsive-padding(x);
  padding-bottom: 30px;
  position: relative;
  text-align: center;
  // transition: transform get-timing(normal);
  z-index: get-z-index('text-reveal-content');
}

.text-reveal__title {
  margin-bottom: 30px;
  text-transform: uppercase;
}


// 1: Relative positioning forces the text to be rendered above the colored
//    overlay. If we just set the z-index, the overlay will still be rendered
//    first because it's set as `position: absolute`
.text-reveal__title,
.text-reveal__desc {
  color: $color--white;
  position: relative; // 1
}

.text-reveal__desc {
  margin-top: 0;
  transition: transform get-timing(fast);

  .button {
    margin-bottom: 20px;
  }
}


// =============================================================================
// Hover State
// -----------------------------------------------------------------------------

// .text-reveal:not(.text-reveal--no-hover):hover,
// .text-reveal:not(.text-reveal--no-hover):focus,
// .text-reveal:not(.text-reveal--no-hover):active {
//   &::before {
//     @include position(absolute, 0 0 0 1px);
//   }

//   .text-reveal__inner {
//     transform: none !important;
//   }

//   .text-reveal__desc {
//     transform: translateY(-40px);
//   }
// }


// =============================================================================
// Column Layouts
// -----------------------------------------------------------------------------

.text-reveals--three-col {
  @include breakpoint(above, small) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1px;

    .text-reveal {
      padding-left: 1px;
      width: percentage(1 / 3);
    }
  }
}
