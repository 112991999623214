$title-bar-border: 1px solid $color--neutral-light;
$title-bar-spacing: (
  'xx-small': 30px null,
  'small': 40px null,
);

// 1: IE 11 does not vertically align items unless the container has an explicit
//    height. We set a height of 0 here to trick IE into vertically aligning.
//    @see
@mixin title-bar {
  @include margin(y, 0);
  @include responsive-padding(x);
  @include responsive-padding(y, $scale: $title-bar-spacing);
  align-items: flex-start;
  background-color: $color--white;
  border-bottom: $title-bar-border;
  border-top: $title-bar-border;
  display: flex;
  flex-direction: column;
  height: auto; // 1
  justify-content: space-around;
  min-height: 100px;
  position: relative;
  width: 100%;
  z-index: get-z-index(title-bar);

  @include breakpoint(above, large) {
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
  }
}
