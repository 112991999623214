// Use the border box model for everything by default
html {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: $max-site-width;
  min-width: $min-site-width;
  overflow-x: hidden;
}

* {
  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

body {
  background: url('../images/noise.jpg') $color--neutral-light;
  position: relative;
  overflow-x: hidden;
}

img {
  max-width: 100%;
  height: auto;
}