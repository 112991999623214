
// 1: Needs to be relative to avoid having the fixed background images on the
//    project page overlap the footer
.page-footer {
  @include responsive-padding(all);
  background: $color--black;
  position: relative; // 1
  z-index: get-z-index(masthead);
}

.page-footer__block {
  color: $color--white;

  .block__title {
    @include font-sizing(F);
    color: $color--white;
  }

  .block__content {
    address,
    li,
    p {
      @include font-sizing(base, 32px);
      color: $color--neutral-light;
      font-weight: normal;
    }

    a {
      border-bottom: 1px solid transparent;
      color: $color--neutral-light;
      transition:
        border-bottom-color get-timing(normal),
        color get-timing(normal);

      &:hover {
        border-bottom-color: $color--primary-dark;
        color: $color--primary;
      }
    }

    > :last-child {
      margin-bottom: 0;
    }

    @include breakpoint(above, small) {
      @include font-sizing(A, 35px);
    }
  }
}

// =============================================================================
// Top Section
// -----------------------------------------------------------------------------

.page-footer__top {
  @include columns(1);
  @include responsive-margin(bottom);
  text-align: center;

  @include breakpoint(above, small) {
    @include columns(2);
    text-align: left;
  }

  @include breakpoint(above, large) {
    @include columns(4);
    margin-bottom: 0;
  }

  .webform-component-email {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 280px;

    @include breakpoint(above, small) {
      margin-left: 0;
    }
  }

  .clientside-error ul {
    @include no-bullets;
  }
}

// =============================================================================
// Bottom Section
// -----------------------------------------------------------------------------

.page-footer__bottom {
  @include responsive-margin(left, -1);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include breakpoint(above, large) {
    flex-direction: row;
  }
}

.page-footer__block {
  @include responsive-padding(left);
}

.page-footer__block--legal {
  text-align: center;

  @include breakpoint(above, large) {
    text-align: left;
    width: 25%;
  }
}
