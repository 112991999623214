////
/// Styles for the Main Menu in the header
/// @group Masthead
////

/// Width of the open mobile menu
$mobile-menu-width: 240px;


// We need the submenu to be visible when a class is added by JS _and_ when
// the parent menu is hovered, so it makes more sense to extend a placeholder
// class than to hard code the styles for each one
@mixin submenu-items {
  max-height: 200px;

  @include breakpoint(above, large) {
    .menu__item:first-child .menu__link {
      // Slide the trapezoids up on hover
      &::before {
        transform: translate(-125%, -100%);
      }

      &::after {
        transform: translate(25%, -100%);
      }
    }
  }
}


// =============================================================================
// Add transitions to relevant containers
// -----------------------------------------------------------------------------

#page,
.masthead {
  transition: transform get-timing(normal) ease-in-out; // 1

  @include breakpoint(above, large) {
    transition: none;
  }
}


// =============================================================================
// Toggle-able Class for mobile
// -----------------------------------------------------------------------------
// On mobile, slide the body to the left when the menu opens
.js-show-menu {
  box-shadow: $mobile-menu-width 0 0 $color--primary;
  transform: translateX($mobile-menu-width * -1) !important;

  @include breakpoint(above, large) {
    box-shadow: 0;
    transform: translateX(0);
    transition: none;
  }

  &.masthead {
    &::after {
      transform: translate(0, -50%);
    }
  }
}



// =============================================================================
// Top Level Menus
// -----------------------------------------------------------------------------

.menu {
  @include no-bullets;
  @include margin(y, 0);
  @include padding(y, 15px);
  @include padding(x, 0);
  @include position(absolute, 0 ($mobile-menu-width * -1) null null);
  background-color: $color--primary;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: $mobile-menu-width;

  @include breakpoint(above, large) {
    @include margin(x, xx-small, -.5);
    @include padding(all, 0);
    background-color: transparent;
    flex-direction: row;
    height: auto;
    left: 0;
    position: static;
    width: auto;
  }
}

.menu__item {
  line-height: 1;

  @include breakpoint(above, large) {
    @include padding(y, 0);
    @include margin(x, xx-small, .5);
  }

  @include breakpoint(above, x-large) {
    @include margin(x, x-small, .5);
  }
}

.menu__link {
  @include padding(x, 30px);
  @include padding(y, 15px);
  @include font-sizing(a);
  color: $color--white;
  display: block;
  line-height: 1;
  text-transform: uppercase;
  transition:
    background-color get-timing(normal),
    color get-timing(normal);

  &:hover,
  &:focus {
    background-color: $color--primary-dark;
    color: $color--white;
  }

  @include breakpoint(above, large) {
    @include padding(all, 0);
    color: $color--black;

    &.active {
      color: $color--primary;
    }

    &:hover,
    &:focus {
      background-color: transparent;
      color: $color--primary;
    }
  }
}

// =============================================================================
// Submenus
// -----------------------------------------------------------------------------

.menu__item--parent {
  cursor: pointer;
  position: relative;

  @include breakpoint(above, large) {
    // Make the submenu visible on hover
    &:hover .menu__submenu {
      @include submenu-items;
    }

    // Change the color of top-level links
    &:hover,
    &.js-show-sub-menu {
      > .menu__link {
        color: $color--primary;
      }
    }
  }

  > .menu__link--separator {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &::after {
      @include icon-font;
      color: $color--white;
      content: '\2304';  // Down Chevron
      transition: transform get-timing(normal) ease-in-out;

      @include breakpoint(above, large) {
        display: none;
      }
    }
  }

  &.js-show-sub-menu > .menu__link--separator {
    &::after {
      transform: rotate(180deg);
    }
  }
}

// =============================================================================
// Submenus
// -----------------------------------------------------------------------------

// 1: Animating the max-height allows us to have a sliding drop-down effect
// 2: Match the padding of the masthead
.menu__submenu {
  @include no-bullets;
  display: block;
  transition: max-height get-timing(normal);
  max-height: 0; // cf. 1
  overflow: hidden;
  position: relative;

  .js-show-sub-menu & {
    @include submenu-items;

    @include breakpoint(below, large) {
      &::before,
      &::after {
        content: '';
        display: block;
        box-shadow: 0 0 10px 5px transparentize($color--black, .8);
        position: absolute;
        width: 100%;
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }
  }

  @include breakpoint(above, large) {
    left: 50%;
    padding-top: 20px; // cf. 2
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    z-index: get-z-index(masthead, +1);

    .menu__item:first-child .menu__link {
      position: relative;

      // Trapezoids
      &::before,
      &::after {
        @include trapezoid(30px, 20px, 10px, 'up', $color--primary);
        @include position(absolute, 0 null null 50%);
        box-shadow: none;
        content: '';
        display: block;
        transition:
          transform get-timing(normal),
          border-bottom-color get-timing(normal),
          box-shadow get-timing(normal);
      }

      &::before {
        transform: translate(-125%, 0);
      }

      &::after {
        transform: translate(25%, 0);
      }
    }
  }

  // ===========================================================================
  // Menu Items & Links
  // ---------------------------------------------------------------------------
  .menu__item {
    @include breakpoint(above, large) {
      @include margin(x, 0);
    }

    &:first-child .menu__link {
      &:hover {
        &::before,
        &::after {
          border-bottom-color: $color--primary-dark;
          box-shadow: 0 10px 0 $color--primary-dark;
        }
      }
    }
  }

  .menu__link {
    background: $color--primary-dark;

    &:hover,
    &:active,
    &:focus {
      background: darken($color--primary-dark, 10%);
    }

    @include breakpoint(above, large) {
      background: $color--primary;
      color: $color--white;
      display: block;
      height: 40px;
      line-height: 40px;
      min-width: 150px;
      text-align: center;
      text-transform: none;
      transition:
        background get-timing(normal),
        box-shadow get-timing(normal);

      &:hover,
      &:active,
      &:focus {
        background: $color--primary-dark;
        box-shadow: inset 0 10px 10px -10px transparentize($color--black, .8);
      }

    }
  }
}
