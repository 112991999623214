.node-type-project {
  // Ensure the Project link in the menu is highlighted
  .menu-mlid-406 > a.menu__link {
    color: $color--primary;
  }
}

.node-project {
  @include responsive-padding(all);
  border-top: 5px solid $color--primary;
  background: $color--white;
  position: relative;
  width: 100%;
  z-index: get-z-index(project-content);

  @include breakpoint(above, large) {
    @include margin(y, large, 2);
    width: 400px;
  }

  @include breakpoint(above, xx-large) {
    @include margin(y, xx-large, 2);
    width: 500px;
  }
}

.project__featured-image img {
  display: block;
  width: 100%;

  @include breakpoint(above, large) {
    display: none;
  }
}

.project__background-image {
  display: none;

  @include breakpoint(above, large) {
    @include position(fixed, 0);
    @include with-background-image;
    display: block;
    transition: opacity get-timing(slow);
    opacity: 0;
    z-index: 0;

    &.active {
      opacity: 1;
    }
  }
}
