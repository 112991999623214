////
/// Generic Typographic Styles
/// @group Typography
////

body {
  @include font-sizing(B);
  font-family: $font-family--normal;

  @include breakpoint(above, large) {
    @include font-sizing(C);
  }
}

// =============================================================================
// Links
// -----------------------------------------------------------------------------

a {
  border-bottom: 1px solid transparent;
  color: $color--primary;
  outline: 0;
  text-decoration: none;
  transition:
    border-bottom-color get-timing('normal'),
    color get-timing('normal');

  &:hover,
  &:focus {
    color: $color--primary-dark;
  }

  &.no-underline {
    border-bottom: 0;
  }
}

.main-content {
  a {
    &:hover,
    &:focus {
      border-bottom-color: $color--primary-dark;
    }
  }
}

// =============================================================================
// Headings
// -----------------------------------------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family--display;
  font-weight: normal;
  margin-top: 0;

  a {
    border-bottom: 0;
    color: inherit;
  }
}

h1 {
  @include font-sizing(G);
  text-transform: uppercase;

  @include breakpoint(above, large) {
    @include font-sizing(H);
  }
}

h2 {
  @include font-sizing(G);
  color: $color--primary;
  width: 100%;

  @include breakpoint(above, large) {
    @include font-sizing(H);
  }
}

h3 {
  @include font-sizing(E);
  @include margin(bottom, 25px);
  color: $color--black;

  @include breakpoint(above, large) {
    @include font-sizing(F);
  }
}

.section__title {
  @include title-bar;
}

.heading--with-icon {
  align-items: center;
  display: flex;

  .icon {
    @include margin(right, xx-small);
    font-size: 40px;
  }
}


// =============================================================================
// Lists
// -----------------------------------------------------------------------------

li,
dt,
dd {
  @include font-sizing(B);

  @include breakpoint(above, large) {
    @include font-sizing(C);
  }
}

li {
  color: $color--black;
  font-weight: bold;
}

dd,
dt {
  color: $color--neutral;
  display: inline-block;
  float: left;
}

dt {
  clear: left;
}

dd {
  margin-left: .5em;

  .hide-visually + & {
    clear: left;
    margin-left: 0;
  }
}

.list--no-bullets {
  @include no-bullets;
}

// Add custom bullets
//
// 1: Remove default bullets and spacing
//
// 2: To get the bullet to line up with the middle of the first line of the
//    text, we have to set it to half of the bullet's line height. If we just
//    used `top: 50%`, the bullet would always end up aligned with the middle
//    of the entire bullet text, even when the text spans multiple lines, e.g.:
//
//         Lorem ipsum dolor sit amet, consectetur adipiscing elit
//      *  quisque rutrum nulla ante, vel posuere nisi mattis feugiat
//         Duis ac nulla sit amet purus sodales pharetra.
.list--with-bullets,
%list--with-bullets {
  @include no-bullets; // 1

  li {
    @include padding(left, 20px);
    margin-bottom: 17px;
    position: relative;

    &::before {
      background-color: $color--black;
      border-radius: 50%;
      content: '';
      display: block;
      height: 5px;
      left: 0;
      position: absolute;
      top: get-line-height(B) / 2; // 2
      transform: translateY(-50%);
      width: 5px;

      @include breakpoint(above, large) {
        top: get-line-height(C) / 2;
      }
    }
  }
}

// Lists with icons
.list--with-icons {
  @include margin(y, 0);
  @include no-bullets;
  font-family: $font-family--display;
  font-weight: normal;

  li {
    @include font-sizing(G);
    align-items: center;
    display: flex;
    text-align: left;

    &:not(:last-child) {
      @include responsive-margin(bottom, .5);
    }

    @include breakpoint(above, large) {
      @include font-sizing(H);
    }
  }

  .icon {
    @include margin(right, xx-small);
    font-size: 40px;
  }
}

// Bare <ul>s should use the default bullet style
.main-content ul:not([class]) {
  @extend %list--with-bullets;
}


// =============================================================================
// Body Copy
// -----------------------------------------------------------------------------

p,
address {
  color: $color--neutral;
  font-style: normal;
}

address {
  margin-bottom: 1em;
}
