// =============================================================================
// Settings
// -----------------------------------------------------------------------------

/// Bottom margin of form items
$spacing-form-input: (
  'xx-small': (20px null),
);

/// Height of inputs
$form-input-height--medium: 40px;
$form-input-height--large: 50px;


// =============================================================================
// Inputs
// -----------------------------------------------------------------------------

.form-item {
  @include responsive-margin(bottom, $scale: $spacing-form-input);
}

[type='text'],
[type='email'],
textarea {
  @include responsive-margin(bottom, .5, $scale: $spacing-form-input);
  background: $color--white;
  border: 0;
  border-radius: 4px;
  color: $color--neutral-medium;
  height: $form-input-height--medium;
  padding: 0 1em;
  transition:
    box-shadow get-timing(fast),
    color get-timing(fast);
  width: 100%;

  &:focus {
    color: $color--neutral;
    outline: none;
  }

  &.error {
    box-shadow: 0 0 0 3px $color--primary;
    color: $color--primary;
  }

  @include breakpoint(above, xx-large) {
    height: $form-input-height--large;
  }

  // Only add bottom margin to the input if the label is not visible
  .element-invisible + & {
    @include responsive-margin(bottom, $scale: $spacing-form-input);
  }
}

.form-textarea-wrapper {
  @include responsive-margin(bottom, .5, $scale: $spacing-form-input);

  // Only add bottom margin to the input if the label is not visible
  .element-invisible + & {
    @include responsive-margin(bottom, $scale: $spacing-form-input);
  }
}

textarea {
  padding-top: floor(($form-input-height--medium - get-line-height(base)) / 2);

  @include breakpoint(above, xx-large) {
    padding-top: floor(($form-input-height--large - get-line-height(base)) / 2);
  }
}


// =============================================================================
// Labels
// -----------------------------------------------------------------------------

label {
  @include font-sizing(A);

  &.error {
    color: $color--primary;
    font-style: italic;
    order: -1;
  }
}

.form-required {
  @include hide-visually;
}


// =============================================================================
// Two Column Layout
// -----------------------------------------------------------------------------

.form--two-column {
  .webform-client-form > div {
    @include responsive-margin(left, -1);
    display: flex;
    flex-wrap: wrap;
  }

  .form-item,
  .form-actions {
    @include responsive-padding(left);
  }

  .form-item {
    display: flex;
    flex-flow: column-reverse;
    justify-content: flex-end;
    width: 100%;

    @include breakpoint(above, x-small) {
      width: 50%;
    }

    @include breakpoint(above, medium) {
      width: 100%;
    }

    @include breakpoint(above, x-large) {
      width: 50%;
    }
  }

  .form-item--full-width {
    width: 100%;
  }

}
