.project-tile {
  &:not(:last-child) {
    border-bottom: 2px solid $color--white;
  }

  a {
    @include responsive-padding(all);
    @include with-background-image;
    background-color: $color--neutral;
    border: 0;
    display: block;
    position: relative;

    &::before {
      @include position(absolute, 0);
      background-color: transparentize($color--black, .5);
      content: '';
      transition: background-color get-timing(normal) ease-in;
      z-index: get-z-index(project-content, -1);
    }

    &::after {
      @extend %button;
      content: 'View Project';
      margin-top: 1em;
      position: relative;
      z-index: get-z-index(project-content);

      @include breakpoint(above, medium) {
        opacity: 0;
        transform: translateY(50px);
        transition:
          background-color get-timing(normal) ease-in,
          opacity get-timing(normal) ease-in,
          transform get-timing(normal) ease-in;
      }
    }

    &:hover {
      &::before {
        background-color: transparentize($color--black, .24);
      }

      &::after {
        @include breakpoint(above, medium) {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
}

.project-tile__location,
.project-tile__title {
  z-index: get-z-index(project-content);
  position: relative;
}

.project-tile__location {
  color: $color--white;
  text-transform: uppercase;
}
