////
/// Styles for news summary cards
/// @group News
////

// 1: Force news cards to be equal height
.news-card {
  @include responsive-margin(bottom, .5);
  display: flex; // 1

  @include breakpoint(above, medium) {
    &:nth-child(n+3):nth-last-child(-n+2) {
      margin-bottom: 0;
    }
  }
}

.news-card__inner {
  @include padding(y, 30px);
  @include padding(x, xx-small);
  background: $color--white;
  box-shadow: 0 1px 5px transparentize($color--black, .8);

  // The cards have non-standard padding at different breakpoints, so we need to
  // explicitly set it based on the measurements from the comps
  @include breakpoint(above, x-small) {
    @include padding(y, xx-small);
    @include padding(x, small);
  }

  @include breakpoint(above, medium) {
    @include padding(all, 40px);
  }

  @include breakpoint(above, x-large) {
    @include padding(all, 50px);
  }
}

.news-card__byline {
  @include news-byline;
}

.news-card__category {
  @include news-category;
}

// Drupal doesn't give us a clean way to add a class to a link field in a
// view, so we have to target the link's parent
.news-card__read-more {
  margin-bottom: 0;

  a {
    @extend %button;
  }
}
