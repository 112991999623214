.back-to-top {
  @include square(50px, transparentize($color--neutral, .6));
  @include responsive-position(right);
  @include responsive-position(bottom, .5);
  align-items: center;
  border-radius: 5px;
  color: $color--white;
  display: flex;
  flex-direction: column;
  font-family: $font-family--display;
  font-weight: normal;
  justify-content: center;
  opacity: 1;
  position: fixed;
  text-transform: uppercase;
  transition:
    background-color get-timing(normal) ease-in,
    opacity get-timing(normal) ease-in;
  z-index: get-z-index(back-to-top);

  @include breakpoint(above, medium) {
    @include square(60px);
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $color--neutral;
    color: $color--white;
  }

  &:not(.js-show) {
    @include hide-visually;
    opacity: 0;
  }
}
