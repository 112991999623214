/// Color definitions
$color--primary: #e06027;
$color--primary-dark: #c55520;

$color--black: #000;
$color--neutral: #707070;
$color--neutral-medium: #b5b5b5;
$color--neutral-light: #ebebeb;
$color--off-white: #f4f4f4;
$color--white: #fff;
