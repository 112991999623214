////
/// Variables related to animation
/// @group Animation
////

/// Default transition speed
$timings: (
  'fast': 125ms,
  'normal': 250ms,
  'slow': 750ms,
  'intro-block': 1000ms,
);


// =============================================================================
// Keyframes
// -----------------------------------------------------------------------------

@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translateX(-150px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in-left {
  from {
    opacity: 0;
    transform: translateX(150px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}


@keyframes fade-up {
  from {
    opacity: 0;
    transform: translateY(150px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rotate-x {
  from {
    transform: translateX(50%) rotateX(90deg);
  }

  to {
    transform: translateX(50%) rotateX(0);
  }
}

@keyframes scroll-down-pulse {
  $opacity-start: .7;
  $opacity-end: 1;

  0% {
    opacity: $opacity-start;
  }

  50% {
    opacity: $opacity-end;
  }

  100% {
    opacity: $opacity-start;
  }
}
