/// The widest the site can be
/// @type Number
/// @group Layout
$max-site-width: 1920px;

/// The narrowest the site can be
/// @type Number
/// @group Layout
$min-site-width: 320px;


// =============================================================================


/// The boundaries for breakpoints
/// @type Map
/// @group Layout
$breakpoints: (
  xx-small: 320px,
  x-small: 480px,
  small: 600px,
  medium: 800px,
  large: 1000px,
  x-large: 1200px,
  xx-large: 1400px,
  xxx-large: 1600px,
);


// =============================================================================


/// Default spacing for containers
/// @type Map
/// @group Layout
$spacing-default: (
  'xx-small': 40px 20px,
  'x-small': 50px 30px,
  'small': 60px 40px,
  'large': 70px 50px,
  'x-large': 90px 60px,
);
