////
/// Styles for the header container
/// @group Masthead
////

.masthead {
  @include responsive-padding(x);
  @include padding(y, 14px);
  background-color: $color--white;
  background-image: url('../images/grunge.jpg');
  background-position: 25% 50%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  min-height: 80px;
  max-width: $max-site-width;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: get-z-index(masthead);

  @include breakpoint(above, medium) {
    @include padding(y, 20px);
  }

  // 1: Add an extra pixel to the right position and trapezoid height to prevent
  //    sub-pixel rounding errors from creating a gap between the sub menu and
  //    the trapezoid
  &::after {
    @include position(absolute, 50% -1px null null);
    @include trapezoid(30px, 20px, 11px, 'left', $color--primary);
    content: '';
    transition: transform get-timing(normal) ease-in-out;
    transform: translate(100%, -50%);

    @include breakpoint(above, large) {
      display: none;
      transition: none;
    }
  }
}

.masthead__navigation {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;

  @include breakpoint(above, large) {
    align-items: flex-end;
    flex-direction: column;
  }
}


// =============================================================================
// With Mobile Menu
// -----------------------------------------------------------------------------
.js-show-menu {
  .masthead {
    &::after {
      transform: translate(0, -50%);
    }
  }
}


// =============================================================================
// Scrolling
// -----------------------------------------------------------------------------

.masthead {
  &.js-show-menu {
    position: fixed;
  }

  &.js-pinned {
    position: fixed;
    transform: translateY(-100%);

    @include breakpoint(above, large) {
      position: absolute;
      transform: none;
      transition: none;
    }
  }

  &.js-maximized {
    transform: translateY(0);
  }

  &.js-minimized {
    visibility: hidden;

    @include breakpoint(above, large) {
      visibility: visible;
    }
  }

  &.js-transitioning {
    transition: transform get-timing(fast) linear;
  }
}