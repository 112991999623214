.affiliations {
  @include no-bullets;
}

.affiliation {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 1em;
  text-align: center;
  width: 100%;

  @include breakpoint(above, small) {
    flex-direction: row;
    text-align: left;
  }
}

.affiliation__logo {
  @include responsive-margin(right, .5);
  border: 0;
  flex-shrink: 0;
  margin-bottom: .5em;

  @include breakpoint(above, small) {
    margin-bottom: 0;
  }

  img {
    display: block;
  }
}
